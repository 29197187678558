import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/ob/interview_sowa.jpg';
import wada from '../images/map/wada.png';
import wadaImg from '../images/map/wadaImg.jpg';
// import wadaTop from '../images/ob/ob_wada_top.jpg';
// import * as ROUTES from '../src/constants/routes';

class WadaPage extends React.Component {

  render() {
    return (
      <Fragment>
      <title>地方部員インタビュー　和田笙平|國學院大學久我山高等学校　バスケットボール部</title>
      <h2 className="content-title-black-ob">地方部員インタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">3年 楚和</h4>
          <div className="mng-text">
          國學院大學久我山高校の入学理由を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={wada} />}
        >
          <h4 className="vertical-timeline-element-subtitle">3年 和田笙平</h4>
          <div className="ob-text">
          そうですね。親の仕事の都合で東京への転勤が決まっていたので、東京でバスケが強いところがいいなと思っていました。様々な学校を調べていくうちに、文武両道を掲げる國學院大學久我山高校と出会いました。勉強も部活もハイレベルな学校は、中々ないと思い入学を決心しました。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          それでは次に、久我山高校へ入学してよかったことを教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={wada} />}
        >
          <div className="ob-text">
          まず最初に頭に浮かぶのはトップレベルでバスケができてることですね。大会では毎回東京都ベスト4の常連ですし、全国大会にも行けるので、そこがいいと思います。次に、礼儀正しさを学べることですね。他校と比べて、挨拶等もしっかりできてると思いますし、「5分前行動」といった社会の常識が身につくので、将来にも繋がると思います。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obImg" alt="sakai" src={wadaImg} />
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。私もバスケ部に入って、「5分前行動」は、体に染み付くようになりましたが、色んな場面で役に立つと私も思います。ちなみに、和田さんは、大阪出身だと思いますが、東京へ引っ越して学校生活を送る上で心配なことはありましたか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={wada} />}
        >
          <div className="ob-text">
          そうですね。実は、小さい頃東京に住んでいたので、交通面などの不安はありませんでしたが、言語面では少しありました。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          関西の方言と標準語のギャップとかありそうですよね。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={wada} />}
        >
          <div className="ob-text">
          そうなんです！東京に来て、標準語を意識しすぎて、結構片言になる場面が多かったり、ある場面で言葉が伝わらなくてちょっと苦労しました。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
言葉の違いの他に雰囲気の違いとかありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={wada} />}
        >
          <div className="ob-text">
          ありますね！大阪では、結構みんなフレンドリーで馴染みやすいですが、東京だと人見知りが多い印象なので話すのに勇気がいりました。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
東京の人ってやっぱり人見知り多いんですか...ちょっとショックを受けたところで（笑）、次の質問に移ります。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={wada} />}
        >
          <div className="ob-text">
          はい(笑)
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
久我山高校バスケ部の入部前と入部後の雰囲気を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={wada} />}
        >
          <div className="ob-text">
          入部前の雰囲気は予想と違って、フレンドリーな感じで、また上下の仲がいいっという感じです。入部後も変わらずフレンドリーな感じで練習やってますね。だけどやっぱり、上下関係はしっかりしていて程よいプレッシャーはあります。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  #eaeaea'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          全国を目指すチームなので、練習が始まるとみんな真剣に取り組みますよね。では最後に、久我山高校バスケ部に興味を持っている中学生に向けてメッセージを下さい！
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: '#eaeaea'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={wada} />}
        >
          <div className="ob-text">
          最初は緊張したり、慣れなくて困ったりすることは多いと思います。だからこそ、勇気を出して先輩や同級生に頼ってコミュニケーションをとり、問題の解決や信頼作りなど頑張ってください。久我山バスケ部の部員は、みんな優しいので、暖かくて迎えてくれると思います！
        </div>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <WadaPage />
  </Layout>
);
